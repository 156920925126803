import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Page404 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Page404')));
const Userprofile = Loadable(lazy(() => import('views/pages/profile/Userprofile')));
const Mycustomer = Loadable(lazy(() => import('views/pages/Mycustomer')));
const Mypartner = Loadable(lazy(() => import('views/pages/Mypartner')));
const Myinactiveleads = Loadable(lazy(() => import('views/pages/Myinactiveleads')));

const Logout = Loadable(lazy(() => import('views/pages/Logout')));
const Learning = Loadable(lazy(() => import('views/pages/Learning')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard',
            children: [
                {
                    path: '/dashboard',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'user-profile',
            children: [
                {
                    path: '/user-profile',
                    element: <Userprofile />
                }
            ]
        },
        {
            path: '/my-customer',
            children: [
                {
                    path: '/my-customer',
                    element: <Mycustomer />
                }
            ]
        },
      
        {
            path: '/my-partner',
            children: [
                {
                    path: '/my-partner',
                    element: <Mypartner />
                }
            ]
        },
        {
            path: '/my-inactive-leads',
            children: [
                {
                    path: '/my-inactive-leads',
                    element: <Myinactiveleads />
                }
            ]
        },
        {
            path: '/learning',
            children: [
                {
                    path: '/learning',
                    element: <Learning />
                }
            ]
        },
       
        {
            path: '*',
            element: <Page404 />
        },
        {
            path: '/logout',
            element: <Logout />
        },
    ]
};

export default MainRoutes;
