// assets
import { IconBrandChrome, IconHelp } from '@tabler/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';
import RequestQuoteRoundedIcon from '@mui/icons-material/RequestQuoteRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
const icons = { IconBrandChrome, IconHelp };
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'Social Profile',
            title: 'Social Profile',
            type: 'item',
            url: '/user-profile',
            icon: AccountBoxIcon,
            breadcrumbs: false
        },
        {
            id: 'My client',
            title: 'Customer',
            type: 'item',
            url: '/my-customer',
            icon: RequestQuoteRoundedIcon,
            breadcrumbs: false
        },
        {
            id: 'My leads',
            title: 'My leads',
            type: 'item',
            url: '/my-inactive-leads',
            icon: FormatListNumberedIcon,
            breadcrumbs: false
        },
        {
            id: 'Business associate',
            title: 'Business associate',
            type: 'item',
            url: '/my-partner',
            icon: AccountTreeIcon,
            breadcrumbs: false
        },
      
        {
            id: 'website',
            title: 'Professionalsaathi',
            type: 'item',
            url: sessionStorage.getItem('slugurl'),
            icon: HelpIcon,
            external: true,
            target: true
        },
        {
            id: 'Learning',
            title: 'Learning',
            type: 'item',
            url: '/learning',
            icon: OndemandVideoRoundedIcon,
            breadcrumbs: false
        }
    ]
};

export default other;
